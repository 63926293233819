<template>
   <div class="page_section" v-if="events.bySlug.id">
      <Banner :content="banner"/>
      <MobileTitle :title="events.bySlug.title"/>
      <ContentElements v-for="(element, index) in events.bySlug.content_elements" :content="element" :key="index"/>
   </div>
</template>

<script>
   import {mapState} from 'vuex';
   import Banner from "../_content_elements/Banner";
   import MobileTitle from "../_common/MobileTitle";
   import ContentElements from "../_content_elements/ContentElements";

   export default {
      name: "Events",
      components: {
         Banner,
         MobileTitle,
         ContentElements,
      },
      computed: {
         ...mapState([
            'events',
            'settings',
         ]),
         banner: function () {
            return {
               title: this.events.bySlug.title,
               image: this.settings.all.events_default_image,
            };
         },
      },
      created() {
         if (this.$route.params.slug) {
            this.$store.dispatch('events/getBySlug', this.$route.params.slug).then(() => {
            }, (error) => {
               if (error.response.status === 404) {
                  this.$router.replace({
                     name: '404', params: {
                        locale: this.$route.params.locale ? this.$route.params.locale : null
                     }
                  });
               }
            });
         }
      },
      beforeDestroy() {
         this.$store.commit('events/setBySlug', {});
      }
   }
</script>

